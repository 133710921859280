import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Menu1 from "../images/Menu/1.webp";
import Menu2 from "../images/Menu/2.webp";
import Menu3 from "../images/Menu/3.webp";
import Menu4 from "../images/Menu/4.webp";
import Menu5 from "../images/Menu/5.webp";
import Menu6 from "../images/Menu/6.webp";
import Menu7 from "../images/Menu/7.webp";
import Menu8 from "../images/Menu/8.webp";
import Menu9 from "../images/Menu/9.webp";
import Menu10 from "../images/Menu/10.webp";

export default function Menu() {
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" name="House 5" description="House 5" />
        <title>House 5 | Menu</title>
      </Helmet>
      <Header />
      <MenuContainer>
        <MenuImg src={Menu1} />
        <div></div>
        <MenuImg src={Menu2} />
        <div></div>
        <MenuImg src={Menu3} />
        <div></div>
        <MenuImg src={Menu4} />
        <div></div>
        <MenuImg src={Menu5} />
        <div></div>
        <MenuImg src={Menu6} />
        <div></div>
        <MenuImg src={Menu7} />
        <div></div>
        <MenuImg src={Menu8} />
        <div></div>
        <MenuImg src={Menu9} />
        <div></div>
        <MenuImg src={Menu10} />
        <div></div>
      </MenuContainer>
      <Footer />
    </main>
  );
}

const MenuContainer = styled.div`
  padding: 10px 80px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-width: 100vw;
  height: auto;

  div {
    margin-bottom: 20px;
  }

  @media (max-width: 600px) {
    padding: 30px 10px;
  }
`;

const MenuImg = styled.img`
  max-width: 100%;
  height: auto;
`;
